<template>
  <div
    class="fixed z-40 top-0 bottom-0 left-0 right-0 h-full w-full bg-black bg-opacity-50 flex justify-end"
  >
    <div class="w-3/4 h-full cursor-pointer" @click="$emit('close')"></div>
    <div class="w-1/4 h-full bg-white rounded-l-2xl p-10 overflow-auto">
      <div class="flex items-center justify-between">
        <h1 class="text-black font-bold text-xl">
          {{ edit ? `Edit ${product.service_name}` : "Add New" }} Service
        </h1>
        <div
          class="bg-red-500 bg-opacity-30 p-5 rounded-full cursor-pointer"
          @click="$emit('close')"
        >
          <CloseIcon />
        </div>
      </div>
      <ValidationObserver v-slot="{ invalid }">
        <form @submit.prevent="createNewProduct" class="text-left mt-10">
          <ValidationProvider
            name="Product Type Name"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label for="item_name">Service Type Name</label>
              <input
                id="name"
                type="text"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="Service Item Type Name"
                v-model="product.service_name"
              /><br />
            </div>
          </ValidationProvider>
          <ValidationProvider
            name="Product Amount"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label for="item_name">Service Amount</label>
              <input
                id="name"
                type="number"
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="Service Amount"
                v-model="product.service_amount"
              /><br />
            </div>
          </ValidationProvider>
          <ValidationProvider
            name="Product Amount"
            rules="required"
            v-slot="{ errors }"
          >
            <div class="mb-5">
              <label>Service Description</label>
              <textarea
                class="border-transparent focus:border-transparent focus:ring-0 focus:outline-none bg-gray-100 py-2 px-3 rounded-md w-full h-44"
                :class="errors[0] ? 'border border-red-400' : ''"
                placeholder="Service Description"
                v-model="product.service_description"
              ></textarea>
              <br />
            </div>
          </ValidationProvider>
          <button
            class="w-full mt-12 text-white py-3 rounded-md bg-opacity-80 transition-all hover:bg-opacity-100 disabled:cursor-not-allowed"
            :class="invalid || submitting ? 'bg-gray-300' : 'bg-primary'"
            :disabled="invalid || submitting"
          >
            Add Product
          </button>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import { createProduct, editService } from "@/services/inventory";

export default {
  name: "AddNewProduct",
  components: {
    CloseIcon: () => import("@/assets/icons/CloseIcon.vue"),
  },
  props: {
    product: {
      required: true,
      type: Object,
    },
    edit: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      submitting: false,
    };
  },
  methods: {
    async createNewProduct() {
      this.submitting = true;
      const payload = {
        service_name: this.product.service_name,
        service_amount: this.product.service_amount,
        service_description: this.product.service_description,
      };
      if (this.edit) {
        try {
          editService(this.product.id, payload);
          this.$emit("created");
        } catch (error) {
          console.log("Error:", error);
          this.$toast.error(error.response.data.message || error);
        }
      } else {
        try {
          await createProduct(payload);
          this.$toast.success("New product created.");
          this.$emit("created");
        } catch (error) {
          this.$toast.error(error.response.data.message || error);
          console.log(error);
        }
      }
      this.submitting = false;
    },
  },
};
</script>

<style></style>
